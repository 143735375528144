import * as React from "react";
import {graphql, Link} from "gatsby";
import {I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout";
import Seo from "../components/seo";
import {replaceCharWithChar} from '../utils/utils';
import {
  touchlessAdvantageDE,
  touchlessAdvantageES,
  touchlessAdvantageZH,
  touchlessAdvantageFR,
  touchlessAdvantageKO,
  goingKeylessElectronicCabinetLocksDE,
  goingKeylessElectronicCabinetLocksES,
  goingKeylessElectronicCabinetLocksKO,
  goingKeylessElectronicCabinetLocksZH,
  goingKeylessElectronicCabinetLocksFR,
  smartMedicineCabinetLocksForHealthcareFacilitiesKO,
  smartMedicineCabinetLocksForHealthcareFacilitiesZH,
  smartMedicineCabinetLocksForHealthcareFacilitiesDE,
  smartMedicineCabinetLocksForHealthcareFacilitiesES,
  smartMedicineCabinetLocksForHealthcareFacilitiesFR,
  whenFileCabinetLocksGetSmartKO,
  whenFileCabinetLocksGetSmartZH,
  whenFileCabinetLocksGetSmartFR,
  whenFileCabinetLocksGetSmartES,
  whenFileCabinetLocksGetSmartDE,
  workplaceStorageSecurityGoesBeyondFileCabinetLocksZH,
  workplaceStorageSecurityGoesBeyondFileCabinetLocksKO,
  workplaceStorageSecurityGoesBeyondFileCabinetLocksES,
  workplaceStorageSecurityGoesBeyondFileCabinetLocksFR,
  workplaceStorageSecurityGoesBeyondFileCabinetLocksDE
} from '../utils/blogTranslations';
import linkedIn from '../images/shared/linkedin.svg';
import youtube from '../images/shared/youtube.svg';
import email from '../images/shared/email-icon-solid.svg';
import CompanySettings from "../constants/company/settings";

const BlogPostTemplate = (props) => {
  const context = React.useContext(I18nextContext);
  const blogPost = props.pageContext.post; //english en

  if (props.pageContext.post.slug === 'rfid-locks-the-touchless-advantage') {
    switch (context.language) {
      case 'es':
        blogPost.title = touchlessAdvantageES().title;
        blogPost.body = touchlessAdvantageES().body;
        blogPost.meta.description = touchlessAdvantageES().desc + '|' + touchlessAdvantageES().title;
        blogPost.meta.title = touchlessAdvantageES().headline;
        break;
      case 'de':
        blogPost.title = touchlessAdvantageDE().title;
        blogPost.body = touchlessAdvantageDE().body;
        blogPost.meta.description = touchlessAdvantageDE().desc + '|' + touchlessAdvantageES().title;
        blogPost.meta.title = touchlessAdvantageDE().headline;
        break;
      case 'zh':
        blogPost.title = touchlessAdvantageZH().title;
        blogPost.body = touchlessAdvantageZH().body;
        blogPost.meta.description = touchlessAdvantageZH().desc + '|' + touchlessAdvantageES().title;
        blogPost.meta.title = touchlessAdvantageZH().headline;
        break;
      case 'fr':
        blogPost.title = touchlessAdvantageFR().title;
        blogPost.body = touchlessAdvantageFR().body;
        blogPost.meta.description = touchlessAdvantageFR().desc + '|' + touchlessAdvantageES().title;
        blogPost.meta.title = touchlessAdvantageFR().headline;
        break;
      case 'ko':
        blogPost.title = touchlessAdvantageKO().title;
        blogPost.body = touchlessAdvantageKO().body;
        blogPost.meta.description = touchlessAdvantageKO().desc + '|' + touchlessAdvantageES().title;
        blogPost.meta.title = touchlessAdvantageKO().headline;
    }
  }
  if (props.pageContext.post.slug === 'going-keyless-with-electronic-cabinet-locks') {
    switch (context.language) {
      case 'es':
        blogPost.title = goingKeylessElectronicCabinetLocksES().title;
        blogPost.body = goingKeylessElectronicCabinetLocksES().body;
        blogPost.meta.description = goingKeylessElectronicCabinetLocksES().desc + '|' + goingKeylessElectronicCabinetLocksES().title;
        blogPost.meta.title = goingKeylessElectronicCabinetLocksES().headline;
        break;
      case 'de':
        blogPost.title = goingKeylessElectronicCabinetLocksDE().title;
        blogPost.body = goingKeylessElectronicCabinetLocksDE().body;
        blogPost.meta.description = goingKeylessElectronicCabinetLocksDE().desc + '|' + goingKeylessElectronicCabinetLocksDE().title;
        blogPost.meta.title = goingKeylessElectronicCabinetLocksDE().headline;
        break;
      case 'zh':
        blogPost.title = goingKeylessElectronicCabinetLocksZH().title;
        blogPost.body = goingKeylessElectronicCabinetLocksZH().body;
        blogPost.meta.description = goingKeylessElectronicCabinetLocksZH().desc + '|' + goingKeylessElectronicCabinetLocksZH().title;
        blogPost.meta.title = goingKeylessElectronicCabinetLocksZH().headline;
        break;
      case 'fr':
        blogPost.title = goingKeylessElectronicCabinetLocksFR().title;
        blogPost.body = goingKeylessElectronicCabinetLocksFR().body;
        blogPost.meta.description = goingKeylessElectronicCabinetLocksFR().desc + '|' + goingKeylessElectronicCabinetLocksFR().title;
        blogPost.meta.title = goingKeylessElectronicCabinetLocksFR().headline;
        break;
      case 'ko':
        blogPost.title = goingKeylessElectronicCabinetLocksKO().title;
        blogPost.body = goingKeylessElectronicCabinetLocksKO().body;
        blogPost.meta.description = goingKeylessElectronicCabinetLocksKO().desc + '|' + goingKeylessElectronicCabinetLocksKO().title;
        blogPost.meta.title = goingKeylessElectronicCabinetLocksKO().headline;
    }
  }
  if (props.pageContext.post.slug === 'smart-medicine-cabinet-locks-for-healthcare-facilities') {
    switch (context.language) {
      case 'es':
        blogPost.title = smartMedicineCabinetLocksForHealthcareFacilitiesES().title;
        blogPost.body = smartMedicineCabinetLocksForHealthcareFacilitiesES().body;
        blogPost.meta.description = smartMedicineCabinetLocksForHealthcareFacilitiesES().desc + '|' + smartMedicineCabinetLocksForHealthcareFacilitiesES().title;
        blogPost.meta.title = smartMedicineCabinetLocksForHealthcareFacilitiesES().headline;
        break;
      case 'de':
        blogPost.title = smartMedicineCabinetLocksForHealthcareFacilitiesDE().title;
        blogPost.body = smartMedicineCabinetLocksForHealthcareFacilitiesDE().body;
        blogPost.meta.description = smartMedicineCabinetLocksForHealthcareFacilitiesDE().desc + '|' + smartMedicineCabinetLocksForHealthcareFacilitiesDE().title;
        blogPost.meta.title = smartMedicineCabinetLocksForHealthcareFacilitiesDE().headline;
        break;
      case 'zh':
        blogPost.title = smartMedicineCabinetLocksForHealthcareFacilitiesZH().title;
        blogPost.body = smartMedicineCabinetLocksForHealthcareFacilitiesZH().body;
        blogPost.meta.description = smartMedicineCabinetLocksForHealthcareFacilitiesZH().desc + '|' + smartMedicineCabinetLocksForHealthcareFacilitiesZH().title;
        blogPost.meta.title = smartMedicineCabinetLocksForHealthcareFacilitiesZH().headline;
        break;
      case 'fr':
        blogPost.title = smartMedicineCabinetLocksForHealthcareFacilitiesFR().title;
        blogPost.body = smartMedicineCabinetLocksForHealthcareFacilitiesFR().body;
        blogPost.meta.description = smartMedicineCabinetLocksForHealthcareFacilitiesFR().desc + '|' + smartMedicineCabinetLocksForHealthcareFacilitiesFR().title;
        blogPost.meta.title = smartMedicineCabinetLocksForHealthcareFacilitiesFR().headline;
        break;
      case 'ko':
        blogPost.title = smartMedicineCabinetLocksForHealthcareFacilitiesKO().title;
        blogPost.body = smartMedicineCabinetLocksForHealthcareFacilitiesKO().body;
        blogPost.meta.description = smartMedicineCabinetLocksForHealthcareFacilitiesKO().desc + '|' + smartMedicineCabinetLocksForHealthcareFacilitiesKO().title;
        blogPost.meta.title = smartMedicineCabinetLocksForHealthcareFacilitiesKO().headline;
    }
  }
  if (props.pageContext.post.slug === 'when-file-cabinet-locks-get-smart') {
    switch (context.language) {
      case 'es':
        blogPost.title = whenFileCabinetLocksGetSmartES().title;
        blogPost.body = whenFileCabinetLocksGetSmartES().body;
        blogPost.meta.description = whenFileCabinetLocksGetSmartES().desc + '|' + whenFileCabinetLocksGetSmartES().title;
        blogPost.meta.title = whenFileCabinetLocksGetSmartES().headline;
        break;
      case 'de':
        blogPost.title = whenFileCabinetLocksGetSmartDE().title;
        blogPost.body = whenFileCabinetLocksGetSmartDE().body;
        blogPost.meta.description = whenFileCabinetLocksGetSmartDE().desc + '|' + whenFileCabinetLocksGetSmartDE().title;
        blogPost.meta.title = whenFileCabinetLocksGetSmartDE().headline;
        break;
      case 'zh':
        blogPost.title = whenFileCabinetLocksGetSmartZH().title;
        blogPost.body = whenFileCabinetLocksGetSmartZH().body;
        blogPost.meta.description = whenFileCabinetLocksGetSmartZH().desc + '|' + whenFileCabinetLocksGetSmartZH().title;
        blogPost.meta.title = whenFileCabinetLocksGetSmartZH().headline;
        break;
      case 'fr':
        blogPost.title = whenFileCabinetLocksGetSmartFR().title;
        blogPost.body = whenFileCabinetLocksGetSmartFR().body;
        blogPost.meta.description = whenFileCabinetLocksGetSmartFR().desc + '|' + whenFileCabinetLocksGetSmartFR().title;
        blogPost.meta.title = whenFileCabinetLocksGetSmartFR().headline;
        break;
      case 'ko':
        blogPost.title = whenFileCabinetLocksGetSmartKO().title;
        blogPost.body = whenFileCabinetLocksGetSmartKO().body;
        blogPost.meta.description = whenFileCabinetLocksGetSmartKO().desc + '|' + whenFileCabinetLocksGetSmartKO().title;
        blogPost.meta.title = whenFileCabinetLocksGetSmartKO().headline;
    }
  }
  if (props.pageContext.post.slug === 'workplace-storage-security-goes-beyond-file-cabinet-locks') {
    switch (context.language) {
      case 'es':
        blogPost.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksES().title;
        blogPost.body = workplaceStorageSecurityGoesBeyondFileCabinetLocksES().body;
        blogPost.meta.description = workplaceStorageSecurityGoesBeyondFileCabinetLocksES().desc + '|' + workplaceStorageSecurityGoesBeyondFileCabinetLocksES().title;
        blogPost.meta.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksES().headline;
        break;
      case 'de':
        blogPost.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksDE().title;
        blogPost.body = workplaceStorageSecurityGoesBeyondFileCabinetLocksDE().body;
        blogPost.meta.description = workplaceStorageSecurityGoesBeyondFileCabinetLocksDE().desc + '|' + workplaceStorageSecurityGoesBeyondFileCabinetLocksDE().title;
        blogPost.meta.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksDE().headline;
        break;
      case 'zh':
        blogPost.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksZH().title;
        blogPost.body = workplaceStorageSecurityGoesBeyondFileCabinetLocksZH().body;
        blogPost.meta.description = workplaceStorageSecurityGoesBeyondFileCabinetLocksZH().desc + '|' + workplaceStorageSecurityGoesBeyondFileCabinetLocksZH().title;
        blogPost.meta.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksZH().headline;
        break;
      case 'fr':
        blogPost.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksFR().title;
        blogPost.body = workplaceStorageSecurityGoesBeyondFileCabinetLocksFR().body;
        blogPost.meta.description = workplaceStorageSecurityGoesBeyondFileCabinetLocksFR().desc + '|' + workplaceStorageSecurityGoesBeyondFileCabinetLocksFR().title;
        blogPost.meta.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksFR().headline;
        break;
      case 'ko':
        blogPost.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksKO().title;
        blogPost.body = workplaceStorageSecurityGoesBeyondFileCabinetLocksKO().body;
        blogPost.meta.description = workplaceStorageSecurityGoesBeyondFileCabinetLocksKO().desc + '|' + workplaceStorageSecurityGoesBeyondFileCabinetLocksKO().title;
        blogPost.meta.title = workplaceStorageSecurityGoesBeyondFileCabinetLocksKO().headline;
    }
  }

  const topic = blogPost.topics[0].name;
  const pubDate = convertDate(blogPost.published);
  let blogPosts = props.pageContext.posts;
  let currentPostId = blogPost.id;
  const topicPosts = [];
  let bpCounter = 0;

  blogPosts.forEach((item, i) => {
    if (item.node.topics[0].name === topic && i > 1 && bpCounter <= 2 && item.node.id !== currentPostId) {
      topicPosts.push(item);
      bpCounter++
    }
  });

  function convertDate(ts) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    let d = new Date(ts);
    return (monthNames[d.getMonth()]) + ' ' + d.getDate() + ', ' + d.getFullYear();
  }

  function toSentenceCase(str) {
    str = str.split('-');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

  const heroImage = props.pageContext.post.feature_image.url;
  let blogBody = props.pageContext.post.body;

  //converts all internal body links to proper country code.
  if (context.language !== 'en') {
    blogBody = replaceCharWithChar(blogBody, 'https://www.digilock.com/', 'https://www.digilock.com/' + context.language + '/');
  }

  //extract first h2
  let closeH2Pos = blogBody.indexOf('</h2>');
  let firstH2 = blogBody.substring(4, closeH2Pos);
  blogBody = blogBody.slice(closeH2Pos + 5);

  /*below for meta title and description, I use the Hubspot description field and split the description and title with a pipe char*/

  return (
      <Layout>
        <Seo
            title={blogPost.meta.description.split('|')[1]}
            description={blogPost.meta.description.split('|')[0]}
        />
        <div className="blog-post-container">
          <section className="">
            <div className="container">
              <span className={'tag'}>{topic}</span>
              <h1 dangerouslySetInnerHTML={{__html: toSentenceCase(blogPost.meta.title)}}/>
              <h2 dangerouslySetInnerHTML={{__html: firstH2}}/>
              <p>{pubDate}</p>
              <img src={heroImage} alt={blogPost.feature_image.alt_text}/>
            </div>
          </section>
          <section className={'blog-body'}>
            <div className="container">
              <div dangerouslySetInnerHTML={{__html: blogBody}}/>
              {
                  topicPosts.length > 0 && <div>
                    <h2>More like this:</h2>
                    {
                      topicPosts.map(function (post) {
                        return (
                            <Link to={'/blog/post/' + post.node.url.split('https://blog.digilock.com/')[1] + '/'}>
                              <img src={post.node.feature_image.url} alt=""/>
                              <h3>{post.node.title}</h3>
                            </Link>
                        )
                      })
                    }
                  </div>
              }
            </div>
          </section>
          <section>
            <div className="container social-icons">
              <a href={CompanySettings.LINKEDIN_USA} target={'_blank'} rel={'noreferrer'}>
                <img src={linkedIn} alt="follow us on linkedin"/>
              </a>
              <a href={CompanySettings.YOUTUBE_AMERICAS} target={'_blank'} rel={'noreferrer'}>
                <img src={youtube} alt="follow us on YouTube"/>
              </a>
              <a href={'mailto:%20?subject=' + blogPost.title + '&body=https://www.digilock.com/blog/post/' + blogPost.slug}
                 rel={'noreferrer'}>
                <img width={37} height={36} src={email} alt="email blog post"/>
              </a>
            </div>
          </section>
          <section className="back-to-blog">
            <div className="container">
              <Link to={'/blog/'}>&lt; Back to all Blogs</Link>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default BlogPostTemplate;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
